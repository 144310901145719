import { isArray, isNil, isObject, mergeWith } from 'lodash';

// Taken from https://stackoverflow.com/a/50770949/2977955
const nilMerge = (a: any, b: any) => (isNil(a) ? b : a);

// Will merge two objects together, replacing null values in the a with values
// from b
export const nilMergeDeep = (a: any, b: any): any =>
  isObject(a) && !isArray(a)
    ? // recursively merge objects with nilMergeDeep customizer
      mergeWith({}, a, b, nilMergeDeep)
    : // let's use our default customizer
      nilMerge(a, b);
