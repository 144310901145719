import React from 'react';

import { Text } from '@/components/text';
import { ProgressBar } from '@/components/ui/progress-bar';
import { tv } from '@/utils/styles';

export interface UsageChartProps {
  label: string;
  limit: number;
  usage: number;
}

const UsageChart = ({ label, usage, limit }: UsageChartProps) => {
  const { base, labelWrapper, text } = styles();

  return (
    <div className={base()}>
      <div className={labelWrapper()}>
        <Text className={text()} variant="sm">
          {label}
        </Text>
        <Text className={text()} variant="sm">
          {usage.toLocaleString()}/{limit.toLocaleString()}
        </Text>
      </div>
      <ProgressBar
        percentage={limit ? (usage / limit) * 100 : 0}
        variant={usage === limit ? 'warning' : 'default'}
        size="sm"
      />
    </div>
  );
};

const styles = tv({
  slots: {
    base: 'flex flex-col gap-2',
    labelWrapper: 'flex justify-between [&_*]:m-0',
    text: 'text-baseSm font-semibold leading-4',
  },
});

export { UsageChart };
