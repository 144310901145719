/**
 * Wraps an element if conditions are met
 *
 * https://blog.hackages.io/conditionally-wrap-an-element-in-react-a8b9a47fab2
 */

import { JSXElementConstructor, ReactElement } from 'react';

interface ConditionalWrapperProps {
  children: ReactElement<any, string | JSXElementConstructor<any>>;
  condition: boolean;
  wrapper: <T>(children: ReactElement<any, string | JSXElementConstructor<any>>) => React.ReactElement<T>;
}

const ConditionalWrapper = ({ condition, wrapper, children }: ConditionalWrapperProps) =>
  // eslint-disable-next-line react/jsx-no-useless-fragment
  condition ? wrapper(children) : <>{children}</>;

export { ConditionalWrapper };
