import { createContext, ReactNode, RefObject, useRef } from 'react';

export const PortalContainerContext = createContext<RefObject<HTMLDivElement> | null>(null);

export const PortalContainer = ({ children, className }: { children: ReactNode; className?: string }) => {
  const ref = useRef<HTMLDivElement>(null);

  return (
    <PortalContainerContext.Provider value={ref}>
      <div ref={ref} className={className}>
        {children}
      </div>
    </PortalContainerContext.Provider>
  );
};
