import { AutoUpdateOptions } from '@floating-ui/react';
import React from 'react';

import { TooltipOptions } from '@/components/tooltip/use-tooltip';

import { TooltipContent, TooltipContentProps } from './tooltip-content';
import { TooltipProvider } from './tooltip-provider';
import { TooltipTrigger } from './tooltip-trigger';

interface TooltipBasicProps extends TooltipOptions, TooltipContentProps {
  children: React.ReactNode;
  content: React.ReactNode;
  whileElementsMountedOptions?: AutoUpdateOptions;
}

export const Tooltip = ({
  initialOpen,
  children,
  content,
  onOpenChange,
  isOpen,
  placement,
  variant,
  followCursor,
  maxWidth,
  className,
  disabled,
  offset,
  trigger,
  whileElementsMountedOptions,
  shouldUsePortal,
}: TooltipBasicProps) => {
  return (
    <TooltipProvider
      initialOpen={initialOpen}
      followCursor={followCursor}
      offset={offset}
      maxWidth={maxWidth}
      isOpen={isOpen}
      trigger={trigger}
      onOpenChange={onOpenChange}
      placement={placement}
      whileElementsMountedOptions={whileElementsMountedOptions}
    >
      <TooltipTrigger asChild>{children}</TooltipTrigger>
      <TooltipContent className={className} disabled={disabled} variant={variant} shouldUsePortal={shouldUsePortal}>
        {content}
      </TooltipContent>
    </TooltipProvider>
  );
};
