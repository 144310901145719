import { UserSettingsApiResponse } from '@/pages/api/user-settings';
import { UserSettingsInterface } from '@/types';
import { request } from '@/utils/api/request';
import { getUserSettingsUrl } from '@/utils/helpers/api-urls';

interface UserSettings {
  update: (settings: UserSettingsInterface) => Promise<UserSettingsApiResponse>;
}

export const userSettingsProvider: UserSettings = {
  update: async (settings) => {
    return request(
      getUserSettingsUrl(),
      {
        body: {
          settings,
        },
      },
      'PATCH',
    );
  },
};
