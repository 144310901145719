/* eslint-disable @next/next/no-img-element */
import { Session } from 'next-auth';

import { Icon } from '@/components/icon';

interface Props {
  readonly user: Session['user'];
  readonly size?: number;
}

const UserAvatar = ({ user, size = 28 }: Props) => {
  return user?.image ? (
    <img className="overflow-hidden rounded-full" src={user.image} width={size} height={size} alt={user.name || ''} />
  ) : (
    <Icon name="user" color="blue.500" />
  );
};

export { UserAvatar };
