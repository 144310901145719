import React, { useImperativeHandle, useState } from 'react';
import useOnclickOutside from 'react-cool-onclickoutside';

import { tv } from '@/utils/styles';

interface Props {
  children: React.ReactNode;
  disabled?: boolean;
  label: React.ReactNode | string;
  name: string;
  right: boolean;
}

export type DropdownRef = {
  closeDropdown: () => void;
} | null;

const Dropdown = React.forwardRef<DropdownRef, Props>(
  ({ children, disabled = false, label, name, right }: Props, ref) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);

    const triggerClose = () => {
      if (isOpen) setIsOpen(false);
    };

    const toggleDropdown = () => {
      if (!disabled) {
        setIsOpen(!isOpen);
      }
    };

    useImperativeHandle(ref, () => ({
      closeDropdown() {
        triggerClose();
      },
    }));

    const internalRef = useOnclickOutside((): void => {
      triggerClose();
    });

    const handleClick = () => toggleDropdown();

    const handleKeyUp = (e: React.KeyboardEvent) => {
      if (e.key === 'enter' || e.key === 'space') {
        handleClick();
      }
    };

    const { base, label: labelStyle, dropdownPanel } = styles({ disabled, isVisible: isOpen, right });

    return (
      <div className={base()}>
        <div role="button" tabIndex={0} onKeyUp={handleKeyUp} className={labelStyle()} onClick={handleClick}>
          {label || 'Missing label'}
        </div>
        <div className={dropdownPanel()} ref={internalRef} id={name}>
          {children || 'No menu items'}
        </div>
      </div>
    );
  },
);

Dropdown.displayName = 'Dropdown';

const styles = tv({
  slots: {
    base: 'relative text-baseSm',
    label: 'cursor-pointer whitespace-nowrap',
    dropdownPanel:
      'absolute left-0 top-full z-16 hidden min-w-40 overflow-hidden rounded bg-white text-grey-800 shadow-md [&_li]:cursor-pointer [&_li]:border-b [&_li]:border-b-grey-200 [&_li]:px-3 [&_li]:py-[0.35rem] [&_li]:hover:bg-grey-100 [&_li]:hover:text-grey-900 [&_ul]:list-none',
  },
  variants: {
    disabled: {
      true: {
        label: 'cursor-default',
      },
    },
    isVisible: {
      true: {
        dropdownPanel: '!block',
      },
    },
    right: {
      true: {
        dropdownPanel: 'left-auto right-0',
      },
    },
  },
});

export { Dropdown };
