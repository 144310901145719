import { Session } from 'next-auth';
import React from 'react';

import { Text } from '@/components/text';
import { UserAvatar } from '@/components/user-nav/user-avatar';
import { tv } from '@/utils/styles';

export interface UserDetailsProps {
  user: Session['user'];
}

const UserDetails = ({ user }: UserDetailsProps) => {
  const { base, avatarWrapper, emailText, text } = styles();
  return (
    <div className={base()}>
      <div>
        <div className={avatarWrapper()}>
          <UserAvatar user={user} size={40} />
        </div>
      </div>
      <div>
        <Text variant="secondary" className={text()}>
          {user.name}
        </Text>
        <Text variant="sm" className={emailText()}>
          {user.email}
        </Text>
      </div>
    </div>
  );
};

const styles = tv({
  slots: {
    base: 'grid grid-cols-[3rem_1fr] items-center gap-1 border-b border-grey-200 pb-3',
    avatarWrapper: 'grid h-10 w-10 place-items-center rounded-full bg-grey-100',
    emailText: 'm-0 max-w-[200px] overflow-hidden text-ellipsis whitespace-nowrap leading-tight text-text-secondary',
    text: 'm-0 font-medium',
  },
});

export { UserDetails };
