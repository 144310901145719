import { History, PlusSquare, UserCircle2, Wallet } from 'lucide-react';
import { Session } from 'next-auth';
import { ReactElement } from 'react';

import { getUpgradeAccountUrl } from '@/utils/helpers/urls';
import { getUserIsAdmin, getUserIsOnFreeTier } from '@/utils/permissions/helpers';

interface Action {
  href: string;
  label: string;
  icon: ReactElement;
}

export const getAvailableNavActions = (session: Session): Record<'workspaceActions' | 'personalActions', Action[]> => {
  const { user } = session;
  if (!user)
    return {
      workspaceActions: [],
      personalActions: [],
    };

  const userIsOnFreeTier = getUserIsOnFreeTier(session?.subscription);
  const userIsAdmin = getUserIsAdmin(user);
  const shouldShowInviteMembers = !userIsOnFreeTier && userIsAdmin;
  const shouldShowPlanAndUsage = !userIsOnFreeTier;

  return {
    workspaceActions: [
      shouldShowPlanAndUsage && { href: getUpgradeAccountUrl(), label: 'Plan & usage', icon: <Wallet size={16} /> },
      shouldShowInviteMembers && {
        href: '/account/organisation/users',
        label: 'Invite members',
        icon: <PlusSquare size={16} />,
      },
    ].filter((action): action is Action => Boolean(action)),
    personalActions: [
      { href: '/account', label: 'Manage account', icon: <UserCircle2 size={16} /> },
      {
        href: '/search/history',
        label: 'Search history',
        icon: <History size={16} />,
      },
    ],
  };
};
