import { AutoUpdateOptions } from '@floating-ui/react';
import React from 'react';

import { TooltipOptions, useTooltip } from './use-tooltip';

type ContextType = ReturnType<typeof useTooltip> | null;

const TooltipContext = React.createContext<ContextType>(null);

export const useTooltipContext = () => {
  const context = React.useContext(TooltipContext);

  if (context == null) {
    throw new Error('Tooltip components must be wrapped in <Tooltip />');
  }

  return context;
};

export const TooltipProvider = ({
  children,
  ...options
}: { children: React.ReactNode } & TooltipOptions & AutoUpdateOptions) => {
  const tooltip = useTooltip(options);
  return <TooltipContext.Provider value={tooltip}>{children}</TooltipContext.Provider>;
};
