'use client';

import { useFeatureFlagVariantKey, usePostHog } from 'posthog-js/react';
import { useEffect, useState } from 'react';

import { FeatureFlag } from '@/types';

export const useFeatureFlag = (flagName: FeatureFlag) => {
  const client = usePostHog();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    client.onFeatureFlags(() => {
      setIsLoading(false);
    });
  }, []);

  const variant = useFeatureFlagVariantKey(flagName);
  return { variant, isLoading };
};
