import React from 'react';

import { tv } from '@/utils/styles';

export interface ProgressBarProps {
  percentage: number;
  variant: 'default' | 'warning';
  size?: 'default' | 'sm';
}

const ProgressBar = ({ percentage, variant = 'default', size = 'default' }: ProgressBarProps) => {
  const { bar, fill } = styles({ variant, size });
  return (
    <div className={bar()}>
      <span className={fill()} style={{ width: `${percentage}%` }} />
    </div>
  );
};

const styles = tv({
  slots: {
    bar: 'relative h-2 w-full overflow-hidden rounded-full bg-grey-300',
    fill: 'absolute bottom-0 left-0 top-0 bg-lightBlue-600 transition-[width] duration-200 ease-linear',
  },
  variants: {
    variant: {
      default: {},
      warning: {
        fill: 'bg-error',
      },
    },
    size: {
      default: {},
      sm: {
        bar: 'h-1',
      },
    },
  },
});

export { ProgressBar };
