import {
  arrow,
  autoUpdate,
  AutoUpdateOptions,
  flip,
  offset,
  OffsetOptions,
  Placement,
  shift,
  size,
  useClick,
  useClientPoint,
  useDismiss,
  useFloating,
  useFocus,
  useHover,
  useInteractions,
  useRole,
} from '@floating-ui/react';
import { useMemo, useRef, useState } from 'react';

export interface TooltipOptions {
  initialOpen?: boolean;
  placement?: Placement;
  isOpen?: boolean;
  onOpenChange?: (open: boolean) => void;
  followCursor?: boolean | 'horizontal' | 'vertical';
  offset?: OffsetOptions;
  trigger?: Array<'focus' | 'hover' | 'click'>;
  maxWidth?: string;
  shouldFlip?: boolean;
  whileElementsMountedOptions?: AutoUpdateOptions;
}

export const ARROW_HEIGHT = 7;
const WHILE_ELEMENTS_MOUNTED_DEFAULTS = {
  ancestorScroll: true,
  ancestorResize: true,
  elementResize: true,
  layoutShift: true,
  animationFrame: false,
};

export const useTooltip = ({
  initialOpen,
  onOpenChange: setControlledOpen,
  isOpen: controlledOpen,
  placement = 'top',
  followCursor,
  offset: offsetOptions,
  trigger = ['hover', 'focus', 'click'],
  maxWidth,
  shouldFlip = true,
  whileElementsMountedOptions,
}: TooltipOptions = {}) => {
  const arrowRef = useRef(null);

  // To allow the tooltip open state to be managed externally, we either use the
  // controlledOpen/setControlledOpen props, or if they are not provided we will
  // manage the open state internally using useState
  const [uncontrolledOpen, setUncontrolledOpen] = useState(initialOpen);
  const open = controlledOpen || uncontrolledOpen;

  const setOpen = setControlledOpen ?? setUncontrolledOpen;

  const data = useFloating({
    placement,
    open,
    onOpenChange: setOpen,
    whileElementsMounted(referenceEl, floatingEl, update) {
      const cleanup = autoUpdate(referenceEl, floatingEl, update, {
        ...WHILE_ELEMENTS_MOUNTED_DEFAULTS,
        ...whileElementsMountedOptions,
      });
      return cleanup;
    },
    middleware: [
      offset(offsetOptions || ARROW_HEIGHT + 2),
      flip({
        crossAxis: shouldFlip,
        mainAxis: shouldFlip,
        fallbackAxisSideDirection: 'start',
        padding: 5,
      }),
      shift({ padding: 10, mainAxis: shouldFlip, crossAxis: shouldFlip }),
      size({
        apply({ availableWidth, elements }) {
          Object.assign(elements.floating.style, {
            maxWidth: maxWidth || `${availableWidth}px`,
          });
        },
      }),
      arrow({
        element: arrowRef,
        padding: 5,
      }),
    ],
  });

  const { context } = data;

  const hover = useHover(context, {
    move: false,
    mouseOnly: true,
    enabled: trigger.includes('hover'),
  });
  const focus = useFocus(context, {
    enabled: trigger.includes('focus'),
  });
  const click = useClick(context, {
    enabled: trigger.includes('click'),
  });
  const dismiss = useDismiss(context);
  const role = useRole(context, { role: 'tooltip' });
  const clientPoint = useClientPoint(context, {
    enabled: followCursor !== undefined,
    axis: followCursor === 'horizontal' ? 'x' : 'y',
  });

  const interactions = useInteractions([hover, focus, click, dismiss, role, clientPoint]);

  return useMemo(
    () => ({
      open,
      setOpen,
      ...interactions,
      ...data,
      arrowRef,
    }),
    [open, setOpen, interactions, data],
  );
};
