import { FloatingArrow, FloatingPortal, useMergeRefs, useTransitionStyles } from '@floating-ui/react';
import React from 'react';
import { tv } from 'tailwind-variants';

import { usePortalContainerRef } from '@/lib/context/portal-container/use-portal-container-ref';

import { useTooltipContext } from './tooltip-provider';
import { ARROW_HEIGHT } from './use-tooltip';

export interface TooltipContentProps {
  variant?: 'light' | 'dark' | 'darker' | 'basic' | 'white' | 'blue';
  className?: string;
  disabled?: boolean;
  shouldUsePortal?: boolean;
}

export const TooltipContent = React.forwardRef<HTMLDivElement, TooltipContentProps & React.HTMLProps<HTMLDivElement>>(
  function TooltipContent(
    { style, children, className, variant = 'dark', disabled, shouldUsePortal = true, ...props },
    propRef,
  ) {
    // If the tooltip is rendered within a portal, mount it inside
    const portalContainer = usePortalContainerRef();
    const { context, middlewareData, arrowRef, getFloatingProps, floatingStyles } = useTooltipContext();
    const ref = useMergeRefs([context.refs.setFloating, propRef]);
    const arrowStyle = {
      light: 'fill-grey-100',
      dark: 'fill-grey-800',
      darker: 'fill-grey-900',
      white: 'fill-white',
      blue: 'fill-blue-700',
      basic: '',
    }[variant];

    const arrowX = middlewareData.arrow?.x ?? 0;
    const arrowY = middlewareData.arrow?.y ?? 0;
    const transformX = arrowX + ARROW_HEIGHT / 2;
    const transformY = arrowY + ARROW_HEIGHT;

    // Set up transition animations for when the tooltip is triggered/dismissed
    const { isMounted, styles } = useTransitionStyles(context, {
      duration: 150,
      initial: ({ side }) => ({
        transform: {
          top: 'translateY(2px)',
          bottom: 'translateY(-2px)',
          left: 'translateX(2px)',
          right: 'translateX(-2px)',
        }[side],
        opacity: 0,
      }),
      common: ({ side }) => ({
        transformOrigin: {
          top: `${transformX}px calc(100% + ${ARROW_HEIGHT}px)`,
          bottom: `${transformX}px ${-ARROW_HEIGHT}px`,
          left: `calc(100% + ${ARROW_HEIGHT}px) ${transformY}px`,
          right: `${-ARROW_HEIGHT}px ${transformY}px`,
        }[side],
      }),
    });

    if (!isMounted || disabled) return null;

    const content = (
      <div
        ref={ref}
        style={{
          ...floatingStyles,
          ...style,
        }}
        {...getFloatingProps(props)}
        className="z-6"
      >
        <div style={styles} className={tooltipStyles({ variant, className })}>
          {children}
          {variant !== 'basic' && (
            <FloatingArrow context={context} ref={arrowRef} height={ARROW_HEIGHT} className={arrowStyle} />
          )}
        </div>
      </div>
    );

    if (shouldUsePortal) return <FloatingPortal root={portalContainer}>{content}</FloatingPortal>;
    return <div className="whitespace-nowrap">{content}</div>;
  },
);

const tooltipStyles = tv({
  base: 'rounded-md px-2 py-1 text-center text-sm leading-5',
  variants: {
    variant: {
      light: 'bg-grey-100 font-medium leading-snug text-text-primary shadow-md',
      dark: 'bg-grey-800 text-white',
      darker: 'bg-grey-900 text-grey-300',
      white: 'relative border border-grey-300 bg-white text-grey-800 shadow-md',
      blue: 'bg-blue-600 text-white',
      basic: '',
    },
  },
});
