'use client';

import { map } from 'lodash';
import { useRef, useState } from 'react';
import { useSetRecoilState } from 'recoil';

import { Dropdown, DropdownRef } from '@/components/dropdown';
import { Icon } from '@/components/icon';
import { useRecoilValueAfterMount } from '@/hooks/use-recoil-value-after-mount';
import { featuresState } from '@/state/features';
import { isProductionEnv } from '@/utils/helpers';
import { tv } from '@/utils/styles';

const IS_NOT_PRODUCTION = !isProductionEnv();

const FeaturesToggle = () => {
  const [forceFeatureToggle, setForceFeatureToggle] = useState<boolean>(false);
  const features = useRecoilValueAfterMount(featuresState, {});
  const setFeatures = useSetRecoilState(featuresState);
  const dropdownRef = useRef<DropdownRef>(null);

  // Show Feature Toggle if is not Production, or Features are forced on
  const shouldShowFeatureToggle = IS_NOT_PRODUCTION || forceFeatureToggle;

  // This adds a public method to Window so we can have a way to toggle the visibility of the Features Dropdown on Production
  // We might want to consider limiting this to users who are signed in with ADMIN permissions in the future?
  // Only lasts for the session
  if (typeof window !== 'undefined') {
    window.flcShowDebug = () => {
      console.log('Fluent Cargo features are now visible');
      setForceFeatureToggle(true);
    };
  }

  const toggleFeature = (key: string, value: boolean): void => {
    // Update features
    setFeatures({ ...features, [key]: value });
    // Possibly undefined?
    if (dropdownRef?.current) {
      dropdownRef.current.closeDropdown();
    }
  };

  const { base, title, dropdownItem } = styles();

  return shouldShowFeatureToggle ? (
    <div className={base()}>
      <Dropdown ref={dropdownRef} name="sort-results" right label="👨‍💻">
        <div className={title()}>Dev Features</div>
        {map(features, (val, key) => (
          <button className={dropdownItem()} onClick={() => toggleFeature(key, !val)} type="button" key={key}>
            {key} {val && <Icon name="check" color="lightBlue.600" size="base" className="ml-sm" />}
          </button>
        ))}
      </Dropdown>
    </div>
  ) : null;
};

const styles = tv({
  slots: {
    base: 'ml-auto text-base md:ml-2 md:self-center',
    title: 'border-b border-b-grey-200 px-4 py-2 text-sm font-semibold uppercase',
    dropdownItem:
      'flex w-full min-w-52 content-between border-b border-b-grey-200 bg-white px-4 py-2 text-left text-baseSm hover:bg-grey-100',
  },
});

export { FeaturesToggle };
